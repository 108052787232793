import { FC, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import type { AppProps } from 'next/app';
// import { ManagedGlobalContext } from '@context/globalStore';
import { ManagedUIContext } from '@context/uiContext';
import Router from 'next/router';
import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress
// import Loader from '@components/common/Loader';
import '@assets/chrome-bug.scss';
import '@assets/main.scss';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'react-toastify/dist/ReactToastify.css';
import 'aos/dist/aos.css';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from '@context/auth';
import Loader from '@components/global/loader';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp: FC<AppProps> = ({ Component, router, pageProps }: AppProps) => {
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => {
      setPageLoading(true);
    };
    const handleComplete = () => {
      setPageLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);
  return (
    <>
      {pageLoading && <Loader />}
      <ManagedUIContext>
        <AuthProvider>
          {/* <ManagedUIContext> */}
          <Component {...pageProps} />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            draggable={true}
            // pauseOnVisibilityChange
            closeOnClick
            pauseOnHover
            // progress
            theme={'colored'}
          />
          {/* </ManagedUIContext> */}
        </AuthProvider>
      </ManagedUIContext>
    </>
  );
};

export default MyApp;
