import axios from 'axios';

class BaseApi {
  constructor(accessToken, subPath) {
    this.config = {};
    if (accessToken) {
      this.config.headers = {
        authorization: `Bearer ${accessToken}`,
      };
    }
    this.apiUrl = process.env.BASE_API_URL + subPath;
  }
  getCurrentUser(accessToken) {
    const options = {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    };
    return axios.get(`${this.apiUrl}/rest/student/v1/info/`, options);
  }
}

export default BaseApi;
