import React, { Component } from 'react';
class Loader extends Component {
  render() {
    return (
      <div className="backdrop-blur bg-white/60 web-loader z-50">
        <div className="flex justify-center">
          <div className="mainLoading"></div>
        </div>
      </div>
    );
  }
}

export default Loader;
